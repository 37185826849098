import React from 'react';

const InputText1 = ({ placeholder, onChange, className = "", ...rest }) => (
    <input 
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        className={`bg-white bg-opacity-60 hover:bg-slate-200 transition-colors duration-200 text-slate-800 font-semibold py-2 px-4 border border-slate-400 border-opacity-60 shadow-md backdrop-blur-md ${className}`}
        {...rest}
    />
);

export default InputText1;
