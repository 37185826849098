import React, { useState } from "react"
import Button2 from "../tailwind-components/Button2"
import SmallButtonLoading from "../tailwind-components/SmallButtonLoading"
import axios from "axios"

const SelectedCustomer = ({ userId, lastSent, requestedCatalogue, fullName, isMember, address, totalValue, mostRecentOrder, returnedEmail, onClose }) => {
    const [requestedStatus, setRequestedStatus] = useState(requestedCatalogue)


    const getSeasonWithYear = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1; // JS months are 0-indexed
        const year = date.getFullYear();

        return month <= 6 ? `Spring ${year}` : `Autumn ${year}`;
    };

    const consumerKey = 'cap.blom@blomsbulbs.com'
    const consumerSecret = 'eGoz Pfse M20p tuBM dSfn cr3h'
    const [isLoading, setIsLoading] = useState(false)

    const handleRequestedChange = async () => {
        const requestData = { user_id: userId }
        const config = {
            url: 'https://blomsbulbs.com/wp-json/users/customer-quick-cat-req/',
            method: 'POST',
            auth: {
                username: consumerKey,
                password: consumerSecret,
            },
            data: requestData,
        }

        try {
            setIsLoading(true)
            const response = await axios(config)
            console.log(response.data.message)
            setRequestedStatus(response.data.requested_catalogue)
            setIsLoading(false)
            setRequestedStatus(response.data.requested_catalogue)
        } catch (error) {
            setIsLoading(false)
            console.log("Error:", error)
        }
    }

    return (
        <div className="fade-in fixed inset-0 flex justify-center items-center z-50">
            <div className="w-1/2 bg-white border flex items-center border-slate-600 border-opacity-60 text-slate-700 px-4 py-3 opacity-70 backdrop-blur-md shadow-lg" role="alert">
                <div className="w-1/2">
                    {isMember === '1' && <h4 className="font-thin">BLOMS PRIVATE MEMBER</h4>}
                    <h3 className="text-2xl font-medium my-2">{fullName}</h3>
                    {address.map((line, index) => (<p key={index} className="font-thin">{line}</p>))}

                    <a
                        href={`mailto:${returnedEmail}`}
                        className="text-slate-700 hover:text-slate-900 underline transition-colors duration-200"
                    >
                        {returnedEmail}
                    </a>

                    <div>
                        <Button2 label="CLOSE" className="my-2 mr-2 fade-in" onClick={onClose} />

                        <Button2 label="VIEW / EDIT CUSTOMER" disabled={true} />
                    </div>

                </div>
                <div className="w-1/2">
                    <div className="bg-sky-100 bg-opacity-40 backdrop-blur-md shadow-lg p-4  border border-sky-400 border-opacity-60 mb-2">
                        <h3 className="font-medium text-sky-600 text-xl my-2">LIFETIME SPEND: £{totalValue}</h3>
                        {mostRecentOrder && (
                            <p className="text-slate-600">
                                Last Ordered: {getSeasonWithYear(mostRecentOrder)}
                            </p>
                        )}
                    </div>

                    {lastSent && <p>LAST SENT: {lastSent}</p>}
                    {isLoading
                        ? <SmallButtonLoading label="LOADING" />
                        : (
                            requestedStatus !== '1'
                                ? <Button2 label="REQUEST CATALOGUE" className="mb-2 mr-2 fade-in" onClick={handleRequestedChange} />
                                : <Button2 label="CANCEL REQUEST" className="mb-2 mr-2 fade-in" onClick={handleRequestedChange} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectedCustomer;
