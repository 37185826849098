import React from "react";
import NavbarItem from "../tailwind-components/NavbarItem";
/**
 * Navbar component.
 * Displays navigation items and handles their selection.
 *
 * @param {Function} onNavbarChange - Function to handle when a navbar item is clicked.
 */
const Navbar = ({ onNavbarChange }) => {
    // List of navigation items with their display names and corresponding component names.
    const menuItems = [
        { name: 'HOME', component: 'Welcome' },
        { name: 'CUSTOMERS', component: 'CustomersMain' },
        { name: 'CATALOGUE REQUESTS', component: 'CatReqMain' },
        { name: 'DOCKETS', component: 'DocketsMain' },
    ];

    return (
        <div className="col-span-2 fade-in">
            {/* Map through menu items to render each as a clickable navigation item */}
            {menuItems.map(item => (
                <div 
                    key={item.name} 
                    className="row-span-1" 
                >
                    <NavbarItem 
                        label={item.name} 
                        onClick={() => onNavbarChange(item.component)} 
                    />
                </div>
            ))}
        </div>
    )
}

export default Navbar;
