import React, { useState } from "react";
import axios from "axios";
import H2 from "../tailwind-components/H2";
import InputText1 from "../tailwind-components/InputText1"
import Button1 from "../tailwind-components/Button1"
import ButtonLoading from "../tailwind-components/ButtonLoading"
import SelectedCustomer from "./SelectedCustomer"

const CustomersMain = () => {
    // initialise state variables for the inputs
    const [postcode, setPostcode] = useState('')
    const [lastName, setLastName] = useState('')
    const [orderId, setOrderId] = useState('')
    const [legacyId, setLegacyId] = useState('')
    const [email, setEmail] = useState('')

    //initialise further state variables that will be passed to SelectedCustomers
    const [isMember, setIsMember] = useState('0')
    const [address, setAddress] = useState([])
    const [totalValue, setTotalValue] = useState(0)
    const [mostRecentOrder, setMostRecentOrder] = useState(null)
    const [fullName, setFullName] = useState(null)
    const [returnedEmail, setReturnedEmail] = useState(null)
    const [lastSent, setLastSent] = useState(null)
    const [catalougeRequested, setCatalogueRequested] = useState('')


    // initilaise api call loading / error state variatbles
    const [apiLoading, setApiLoading] = useState(false)
    const [apiHasError, setApiHasError] = useState(false)

    const [inputErrors, setInputErrors] = useState([])

    // initialise state variable for returned data
    const [users, setUsers] = useState([]);
    const [noResults, setNoResults] = useState(false)


    const [isCustomerSelected, setIsCustomerSelected] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState(null)

    // api credentials
    const consumerKey = 'cap.blom@blomsbulbs.com'
    const consumerSecret = 'eGoz Pfse M20p tuBM dSfn cr3h'

    const validateInputs = () => {
        let errors = [];

        const hasValue = input => input && input.trim().length > 0;
        const isValidEmail = input => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input); // Basic email regex

        if (![postcode, lastName, orderId, legacyId, email].some(hasValue)) {
            errors.push("At least one search parameter is required.");
        }

        [postcode, lastName, orderId, legacyId, email].forEach(input => {
            if (input.length > 50) {
                errors.push("Please try again, the search parameter(s) are invalid.");
            }
        });

        if (email && !isValidEmail(email)) {
            errors.push("Please enter a valid email address.");
        }

        setNoResults(false)

        setInputErrors(errors);
        return errors.length === 0; // return true if there are no errors
    };


    // api call
    const handleSearch = async () => {
        if (!validateInputs()) {
            return
        }
        setApiLoading(true)
        setApiHasError(false)
        setNoResults(false)
        const requestData = {
            postcode,
            last_name: lastName,
            order_id: orderId,
            legacy_id: legacyId,
            email,
        };

        const config = {
            url: 'https://blomsbulbs.com/wp-json/users/customer-main-search',
            method: 'POST',
            auth: {
                username: consumerKey,
                password: consumerSecret,
            },
            data: requestData,
        }

        try {

            const response = await axios(config)

            console.log(response.data)

            setUsers(response.data.users)
            if (response.data.users.length === 0) {
                setNoResults(true)
            }

        } catch (error) {
            console.log("Error:", error)
            setApiHasError(true)
        } finally {
            setApiLoading(false)

            // Clear all input fields after collecting their values
            setPostcode('');
            setLastName('');
            setOrderId('');
            setLegacyId('');
            setEmail('');
        }
    }


    return (


        <>
            <div className={`relative ml-6 col-start-3 col-span-7 fade-in ${isCustomerSelected ? 'blur-md' : ''}`}>





                <H2 heading="CUSTOMERS" className="mt-2" />

                <p className="text-base leading-relaxed mb-4">
                    Welcome to the customer search page. You can search for customers using their postcode, surname, order number, customer number, or email and pressing the "SEARCH" button.
                </p>


                {inputErrors.length > 0 && inputErrors.map((error, index) => (
                    <p key={index} className="fade-in mb-2 text-base leading-relaxed text-red-500">{error}</p>
                ))}

                <InputText1
                    className="mb-2 mr-2"
                    placeholder="Postcode"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />

                <InputText1
                    className="mb-2 mr-2"
                    placeholder="Surname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />

                <InputText1
                    className="mb-2 mr-2"
                    placeholder="Order Number"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />

                <InputText1
                    className="mb-2 mr-2"
                    placeholder="Customer Number"
                    value={legacyId}
                    onChange={(e) => setLegacyId(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />

                <InputText1
                    className="mb-2 mr-2"
                    placeholder="Email"
                    value={email} onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />

                {!apiLoading &&
                    <Button1 label="SEARCH" className="mb-2 mr-2 fade-in" onClick={handleSearch} />
                }

                {apiHasError &&
                    <p className="text-base leading-relaxed mb-4">SOMETHING HAS GONE HORRIBLY WRONG! Please try again.</p>
                }

                {apiLoading &&
                    <ButtonLoading label="SEARCHING" className="fade-in mb-2 mr-2" />}


                {/* Display table if users data is available */}
                {noResults &&
                    <p className="fade-in mb-2 text-base leading-relaxed text-red-500">No customers have been found. Please try again using different parameters.</p>}

                {users.length > 0 && (
                    <table className="mt-3 min-w-full table-auto bg-white shadow-md overflow-hidden fade-in">
                        <thead className="bg-slate-700 text-white">
                            <tr>
                                <th className="text-left py-3 px-4">CUSTOMER</th>
                                <th className="text-left py-3 px-4">NAME</th>
                                <th className="text-left py-3 px-4">ADDRESS</th>
                                <th className="text-left py-3 px-4">POSTCODE</th>
                                <th className="text-left py-3 px-4">LAST SENT</th>
                            </tr>
                        </thead>
                        <tbody className="text-slate-700">
                            {users.map((user, index) => (  // <-- include the index here

                                <tr
                                    className={`${index % 2 === 0 ? 'bg-slate-100 hover:bg-slate-200' : 'hover:bg-slate-300'} transition-colors duration-100 cursor-pointer`}
                                    key={user.user_id}
                                    onClick={() => {
                                        setSelectedCustomer(user.user_id)
                                        setIsCustomerSelected(true)
                                        setIsMember(user.is_member)
                                        setAddress(user.address)
                                        setTotalValue(parseFloat(user.total_order_value).toFixed(2))
                                        setMostRecentOrder(user.most_recent_order_date)
                                        setFullName(user.full_name)
                                        setReturnedEmail(user.returned_email)
                                        setLastSent(user.last_catalogue_sent)
                                        setCatalogueRequested(user.catalogue_requested)
                                    }}
                                >

                                    <td className="py-2 px-4">{user.legacy_id}</td>
                                    <td className="py-2 px-4">{user.last_name}</td>
                                    <td className="py-2 px-4">{user.billing_address_1}</td>
                                    <td className="py-2 px-4">{user.billing_postcode}</td>
                                    <td className="py-2 px-4">{user.last_catalogue_sent}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
                }

            </div >

            {isCustomerSelected &&
                <SelectedCustomer
                    userId={selectedCustomer}
                    isMember={isMember}
                    address={address}
                    totalValue={totalValue}
                    mostRecentOrder={mostRecentOrder}
                    fullName={fullName}
                    returnedEmail={returnedEmail}
                    lastSent={lastSent}
                    catalogueRequested={catalougeRequested}
                    onClose={() => setIsCustomerSelected(false)}
                />
            }

        </>
    )
}

export default CustomersMain;
