import React from "react";

const Welcome = () => {
    return (
        <div className="col-start-3 col-span-8">
            <h1>WELCOME</h1>
        </div>
    )
}

export default Welcome