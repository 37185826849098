import React from 'react';

const Button2 = ({ label, onClick, className = "", disabled = false }) => (
    <button 
        className={`
            ${disabled ? 'bg-slate-300 cursor-not-allowed opacity-50' : 'bg-white hover:bg-slate-200 transition-colors duration-200 text-slate-800'}
            bg-opacity-60 font-medium py-1 px-2 text-sm border border-slate-400 border-opacity-60 shadow-sm backdrop-blur-md ${className}
        `}
        onClick={onClick}
        disabled={disabled}
    >
        {label}
    </button>
);

export default Button2;
