import React from "react";

const CatReqLabels = ({ catReqData }) => {
    return (
        <div>
            {/* Headers */}
            "Title","First Name","Last Name","Billing Address 1","Billing Address 2","City","Postcode"
            
            {/* Data Rows */}
            {catReqData.map(item => (
                <div key={item.user_id}>
                    "{item.title}","{item.first_name}","{item.last_name}","{item.billing_address_1}","{item.billing_address_2 || ''}","{item.city}","{item.billing_postcode}"
                </div>
            ))}
        </div>
    );
}

export default CatReqLabels;
