import React from 'react';

const SmallButtonLoading = ({ label, className = "" }) => (
    <div 
        className={`
            inline-block
            bg-sky-300 bg-opacity-70 
            transition-opacity duration-300 ease-in-out 
            text-sky-900 font-medium py-1 px-2 text-sm 
            border border-sky-500 border-opacity-70 
            shadow-sm backdrop-blur-md 
            animate-blink 
            ${className}
        `}
    >
        {label}
    </div>
);

export default SmallButtonLoading;
