import React, { useState } from 'react';
import Navbar from './navigation/Navbar';
import CatReqMain from './catalogue-request/CatReqMain';
import Welcome from './Welcome';
import CustomersMain from './customers/CustomersMain';
 

function App() {
  // State to track the currently active navigation item.
  const [activeNavigation, setActiveNavigation] = useState('Welcome');

  // Mapping of navigation items to their corresponding components.
  // This makes it easier to extend and manage conditional rendering.
  const componentMapping = {
    'Welcome': <Welcome />,
    'CatReqMain': <CatReqMain />,
    'CustomersMain': <CustomersMain />
  };

  return (
    <div className="container grid grid-cols-10 gap-y-1 bg-slate-100 text-slate-900">
      {/* Navbar component. When an item is clicked, the active navigation state is updated. */}
      <Navbar onNavbarChange={setActiveNavigation}/>

      {/* Render the component based on the active navigation state using the mapping. */}
      {componentMapping[activeNavigation]}
    </div>
  );
}

export default App;
