import React from 'react';

const ButtonLoading = ({ label, className = "" }) => (
    <div 
        className={`
            inline-block
            bg-sky-100 bg-opacity-40 
            transition-opacity duration-300 ease-in-out 
            text-sky-600 font-semibold py-2 px-4 
            border border-sky-400 border-opacity-70 
            shadow-md backdrop-blur-md 
            animate-blink 
            ${className}
        `}
    >
        {label}
    </div>
);
 
export default ButtonLoading;
