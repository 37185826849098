



import React, { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { createRoot } from 'react-dom/client'
import CatReqLabels from "./CatReqLabels"
import Button1 from "../tailwind-components/Button1"
import H2 from "../tailwind-components/H2"

const CatReqMain = () => {
    const [catReqData, setCatReqData] = useState([])
    const [userIds, setUserIds] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [retryCount, setRetryCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)

    const consumerKey = 'cap.blom@blomsbulbs.com'
    const consumerSecret = 'eGoz Pfse M20p tuBM dSfn cr3h'

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const config = {
                url: 'https://blomsbulbs.com/wp-json/users/catalogue-requested',
                method: 'get',
                auth: {
                    username: consumerKey,
                    password: consumerSecret,
                },
            }

            const response = await axios(config)
            setCatReqData(response.data)
            const fetchedUserIds = response.data.map(item => item.user_id)
            setUserIds(fetchedUserIds)
        } catch (error) {
            if (retryCount < 3) {
                setRetryCount(retryCount + 1)
                fetchData()
            }
        } finally {
            setLoading(false)
        }
    }, [retryCount, consumerKey, consumerSecret])

    const handleResetList = async () => {
        setLoading(true)
        try {
            const config = {
                url: 'https://blomsbulbs.com/wp-json/users/reset-catalogue-requested',
                method: 'post',
                data: { userIds },
                auth: {
                    username: consumerKey,
                    password: consumerSecret,
                },
            }

            await axios(config)
        } catch (error) {
            // Handle the error
        }
        setLoading(false)
        setShowSuccessAlert(true)
    }

    const handleGenerateLabels = () => {
        const newWindow = window.open('', '_blank')
        const container = newWindow.document.createElement('div')
        newWindow.document.body.appendChild(container)
        const root = createRoot(container)
        root.render(<CatReqLabels catReqData={catReqData} />)
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const resetCatReqMain = () => {
        setShowTable(false)
        setShowSuccessAlert(false)
        fetchData()
    }

    return (
        <div className="relative col-start-4 col-span-6 fade-in">
            <div className={`${showSuccessAlert ? 'blur-md' : ''}`}>
                <H2 heading="CATALOGUE REQUESTS" className="mt-2" />

                <p className="text-base leading-relaxed mb-4">This page displays a list of customers who've asked for a catalogue. To toggle the list, simply press the "TOGGLE CATALOGUE REQUESTS" button. If you need to prepare labels for mailing, use the "GENERATE LABELS" button. After sending out the catalogues, remember to click "RESET LIST" to ensure we don't mail duplicates.</p>
    
                {loading && <p>LOADING...</p>}
    
                {!loading && catReqData.length > 0 && (
                    <>
                        <p className="text-base leading-relaxed mb-2">There are {catReqData.length} catalogue requests outstanding</p>
                        <Button1 label="SHOW / HIDE CATALOGUE REQUESTS" onClick={() => setShowTable(!showTable)} className="mb-2" />
                    </>
                )}
    
                {!loading && catReqData.length === 0 && <p>There are no catalogue requests.</p>}
    
                {showTable && catReqData.length > 0 && (
                    <table className="mt-3 min-w-full table-auto bg-white shadow-md overflow-hidden fade-in">
                        <thead className="bg-slate-700 text-white">
                            <tr>
                                <th className="text-left py-3 px-4">CUSTOMER NUMBER</th>
                                <th className="text-left py-3 px-4">SURNAME</th>
                                <th className="text-left py-3 px-4">POSTCODE</th>
                                <th className="text-left py-3 px-4">REQUESTED</th>
                            </tr>
                        </thead>
                        <tbody className="text-slate-700">
                            {catReqData
                                .sort((a, b) => new Date(b.last_catalogue_requested) - new Date(a.last_catalogue_requested))
                                .map((item, index) => (
                                    <tr key={item.user_id} className={index % 2 === 0 ? 'bg-slate-100' : ''}>
                                        <td className="py-2 px-4">{item.legacy_id}</td>
                                        <td className="py-2 px-4">{item.last_name}</td>
                                        <td className="py-2 px-4">{item.billing_postcode}</td>
                                        <td className="py-2 px-4">{item.last_catalogue_requested}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
    
                {!loading && catReqData.length > 0 && (
                    <>
                        <br />
                        {loading ?
                            <Button1 label="WORKING" disabled className="mb-2 fade-in" />
                            :
                            <>
                                <Button1 label="GENERATE LABELS" onClick={handleGenerateLabels} className="mr-2 mb-2" />
                                <Button1 label="RESET LIST" onClick={handleResetList} className="mb-2" />
                            </>
                        }
                    </>
                )}
    
            </div>
    
            {showSuccessAlert && (
                <div className="fade-in absolute inset-0 flex justify-center items-center">
                    <div className="bg-white border border-emerald-600 border-opacity-60 text-emerald-700 px-4 py-3 opacity-70 backdrop-blur-md shadow-lg" role="alert">
                        <p className="font-bold text-base leading-relaxed">CATALOGUE REQUESTS HAVE BEEN RESET</p>
                        <p className="text-base leading-relaxed">
                            Please click <button onClick={resetCatReqMain} className="text-emerald-500 underline focus:outline-none">here</button> to aknowledge.
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CatReqMain
