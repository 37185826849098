import React from "react";

const NavbarItem = ({ label, onClick, className = "" }) => {
    return (
        <h1 
            className={`text-xl font-bold hover:bg-slate-200 cursor-pointer px-4 py-2 transition-colors duration-200 backdrop-blur-md bg-white bg-opacity-60 hover:bg-opacity-70 border border-slate-400 border-opacity-60 shadow-md mt-2 ${className}`} 
            onClick={onClick}
        >
            {label}
        </h1>
    );
}

export default NavbarItem;
